$(function() {
  $('.mobile-sidebar-toggler').on('click', function(){
    $('body.portal').toggleClass('sidebar-open');
  });
});

$(function() {
  $('#portal-profile-edit-btn').on('click', function(){
    $("#portal-profile-edit").removeClass('d-none');
    $("#portal-profile-view").addClass('d-none');
  });
  $('#portal-profile-edit-cancel-btn').on('click', function(){
    $("#portal-profile-edit").addClass('d-none');
    $("#portal-profile-view").removeClass('d-none');
  });
});

$(function() {
	if($.cookie("referrer") == "app"){
		$('.aura-sidebar').addClass("app-d-none");
    $('.aura-header').addClass("app-d-none");
  }
});
$(document).ready(function() {
  if ( _.get($.siteConfiguration,"enable_portal" ) ){
    renderMarkdown($('#portal-page-headers'), $.polyglot.t('portal_page_headers', $.interpolations));
  }

  renderMarkdown($('#footer-text'), $.polyglot.t('footer_text', $.interpolations));

  if ( _.get($.siteConfiguration,"enable_portal_disclaimer" ) ){
    renderMarkdown($('#portal-disclaimer'), $.polyglot.t('portal_disclaimer', $.interpolations));
  }
});